@import '../../scss/variables';

.loader {
    background: rgba($mainColor, 0.7);
    font-size: 20px;
    font-weight: bold;
    color: white;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100;

    > div {
        margin-top: 30px;
    }
}