@import '../../scss/variables';

.footer {
    border-top: 2px solid $bgRectAccent;
    color: white;
    margin-top: 40px;

    &-content {
        max-width: 1170px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100px;
        padding: 0 20px;
    }

    .divider {
        border-left: 1px solid;
        height:  20px;
        padding-left: 20px;
        margin-left: 20px;
    }

    a {
        text-decoration: none;
        font-weight: bold;
        color: inherit;
        transition: all 300ms ease;
        &:hover {
            opacity: 0.5;
        }
    }
    img {
        max-height: 50px;
        margin-right: 50px;
    }
}

@media screen and (max-width: 799px) {
    .footer-content {
        flex-direction: column;
        padding: 20px;
        height: unset;

        img {
            order: 1;
            margin: 0;
            margin-bottom: 16px;
        }
        a {
            order: 2;
            margin-bottom: 16px;
        }
        div {
            order: 3;
            font-size: 14px;
        }
    }
}
