$black: #181722;
$light-grey: #e9edf6;
$pale-grey: #f3f5fa;
$blue-grey: #9fa8bb;
// $mainColor: #278aff;
$mainColor: #f72531;
$light-blue-grey: #c6d0e6;
$blue-70: rgba(38, 138, 255, 0.7);
$error: #e4352b;

// Background scattered rectangles
$bgRectMain: #f72531;
$bgRectAccent: #ff8289;
// Background gradient color