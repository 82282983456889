@import '../../../../shared/scss/variables';

.attribute {
    height: 60px;
    background: white;
    border: 2px solid $black;
    border-radius: 10px;
    margin-bottom: 10px;
    color: $black;
    padding: 15px 15px 15px 25px;
    font-size: 15px;
    line-height: 25px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    transition: all 300ms ease-in-out;
    opacity: 0;
    animation: fadeInFromBottom 300ms ease 150ms forwards;

    &:hover {
        cursor: pointer;
    }

    .checkbox {
        width: 28px;
        height: 28px;
        border-radius: 5px;
        border: 2px solid $black;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;

        &.round {
            border-radius: 50%;
        }
    }

    &.selected {
        background: $light-grey;
        color: $black;

        .checkbox {
            background: $mainColor;
            border-color: $mainColor;
        }
    }

    &.not-animated {
        animation: none;
        opacity: 1;
    }
}