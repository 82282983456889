@import '../../shared/scss/variables';
@import '../../shared/scss/animations';

$uvi-red: #FE525C;

* {
    box-sizing: border-box;
}
.categories-bg, .arguments-bg {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.categories-bg {
    background-color: $uvi-red;
    // background-image: 
    //     linear-gradient(150deg, transparent, transparent 1100px, white 1100px),
    //     linear-gradient(#f4f3f3, rgba(248, 250, 254, 0.15));
}
.arguments-bg {
    min-height: unset;
    position: relative;
    background-color: $uvi-red;
    // background-image: 
    //     linear-gradient(155deg, white, white 1200px, transparent 1200px),
    //     linear-gradient(#f4f3f3, rgba(248, 250, 254, 0.15));
}
.main-container, .flex {
    flex: 1;
    z-index: 1;
}
.container {
    max-width: 770px;
    width: 770px;
    margin: 0 auto;
}
.small-container {
    max-width: 570px;
    width: 570px;
    margin: 0 auto;
}
.bg-element {
    position: absolute;
    z-index: 0;
    path {
        fill: $bgRectMain;
    }
    &.accent path {
        fill: $bgRectAccent;
    }
}

@media screen and (max-width: 1199px) {
    .bg-element {
        display: none;
    }
}

@media screen and (max-width: 770px) {
    .container {
        width: 100%;
        padding: 0 20px;
    }
}

@media screen and (max-width: 610px) {
    .small-container {
        width: 100%;
        padding: 0 20px;
    }
}