@import 'src/shared/scss/variables';
.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 36px 20px 0px;
    img {
        max-height: 60px;
    }
    img.bigLogo {
        max-height:200px;
        margin-top:25px;
    }
    .beta {
        font-size: 10px;
        font-weight: 900;
        text-transform: uppercase;
        color: white;
    }
    
}
