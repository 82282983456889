@import 'src/shared/scss/variables';

.notification {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px 20px;
    background: $error;
    border-radius: 10px;
    color: white;
    margin: 20px;
    animation: fadeInFromRight 300ms ease;
}