@import '../../scss/variables';

.button {
    height: 60px;
    line-height: 60px;
    font-size: 15px;
    color: white;
    padding: 0 60px;
    background: $black;
    border: 0;
    min-width: 200px;
    border-radius: 10px;
    box-shadow: 0 20px 50px 0 rgba(51, 85, 125, 0.15);
    outline: none;
    cursor: pointer;

    @media screen and (max-width: 399px) {
        width: 100%;
    }
}