@import '../../../../shared/scss/variables';

.weblinkCard {
    padding: 10px 10px 25px;
    background: white;
    border-radius: 20px;
    box-shadow: 0 10px 80px 0 rgba(51, 85, 125, 0.1);
    text-decoration: none;
    border: 2px solid transparent;
    opacity: 0;
    animation: fadeInFromBottom 300ms ease 100ms forwards;

    &-img {
        background: #eee;
        height: 165px;
        border-radius: 15px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 20px;
        position: relative;
        overflow: hidden;
    }
    &-relevance {
        position: absolute;
        top: 0;
        right: 0;
        height: 27px;
        color: white;
        background-color: #55d492;
        font-size: 12px;
        line-height: 20px;
        border-bottom-left-radius: 15px;
        padding: 2px 10px 5px;
    }
    &-info {
        padding: 0 20px;
    }
    h2 {
        font-size: 20px;
        line-height: 30px;
        color: $black;
        opacity: 0.5;
        margin: 0;
        word-break: break-all;
    }
    p {
        font-size: 15px;
        line-height: 25px;
        color: black;
        margin: 0 0 5px;
        max-width: 185px;
        opacity: 0.5;
    }
    &-text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;    
        @supports (-webkit-line-clamp: 2) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
    &-date {
        opacity: 0.25;
    }
    &-arrow {
        position: absolute;
        right: 0;
        bottom: 0;
        background: white;
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 15px;
        opacity: 0;
        transition: all 300ms ease;
    }

    &:hover {
        cursor: pointer;
        .weblinkCard-arrow {
            opacity: 1;
        }
    }
}

.weblinkCard.new {
    border-color: $mainColor;
    box-shadow: 0 30px 80px 0 rgba(51, 85, 125, 0.2);

    h2, p{
        opacity: 1;
    }
    .weblinkCard-date {
        opacity: 0.5;
    }
}