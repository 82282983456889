@import 'src/shared/scss/variables';

.attribute-group {
    border: 2px solid $light-grey;
    border-radius: 15px;
    padding: 20px 10px 0;
    margin-bottom: 10px;
    opacity: 0;
    animation: fadeInFromBottom 300ms ease 150ms forwards;

    &-title {
        margin-bottom: 15px;
        font-size: 20px;
        line-height: 30px;
        color: $black;
        font-weight: 900;
    }
}