@import '../../../../shared/scss/variables';

.argumentLink {
    height: 70px;
    background: white;
    border: 2px solid $pale-grey;
    border-radius: 10px;
    padding: 25px 20px 20px;
    text-align: center;
    font-size: 25px;
    line-height: 25px;
    font-weight: 600;
    margin-bottom: 30px;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    transition: all 500ms ease;

    &-content {
        transition: all 200ms ease;
        > div {height: 70px;}
        > div:last-child {
            color: white;
        }
    }
}

.argumentLink.copied {
    background: rgba($mainColor, 0.7);

    .argumentLink-content {
        transform: translateY(-70px);
    }
}


@media screen and (max-width: 700px) {
    .argumentLink {
        font-size: 3.5vw;
    }
}