@import '../../../../shared/scss/variables';

.argumentRelevance {
    width: 120px;
    min-width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &-fade {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background-image: linear-gradient(rgba(57, 225, 151, 0.7), rgba(198, 208, 230, 0) 50%);
        position: absolute;
        opacity: 0.6;
        transition: all 500ms ease;
    }

    &-inner {
        position: relative;
        width: 82px;
        height: 82px;
        z-index: 2;
    }
    &-arrow {
        width: 82px;
        height: 82px;
        border-radius: 50%;
        background: white;
        box-shadow: 0 30px 80px 0 rgba(51, 85, 125, 0.15);
        position: absolute;
        z-index: 0;
        transition: all 500ms ease;
    }
    &-info {
        width: 82px;
        height: 82px;
        border-radius: 50%;
        background: white;
        color: $blue-grey;
        font-size: 10px;
        line-height: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 1;
        h3 {
            font-size: 20px;
            line-height: 20px;
            font-weight: 900;
            color: $black;
            margin: 0;
        }
    }

    .progress-ring {
        position: absolute;
        stroke-width: 5px;
        stroke: #39e197;
        stroke-linecap: round;
        fill: transparent;
        transform: rotate(180deg);
        z-index: 1;
        
        circle {
            transition: all 500ms ease;
        }
    }

    .triangle {
        width: 10px;
        height: 10px;
        margin-left: -5px;
        margin-top: 36px;
        background: white;
        -webkit-transform: rotate(45deg);
        transform: rotate(135deg);
        border-radius: 3px;
        box-shadow: 1px 2px 12px 0 rgba(51, 85, 125, 0.15);
        z-index: 0;
    }
    .dot {
        width: 13px;
        height: 13px;
        background: white;
        border-radius: 50%;
        border: solid 3px;
        margin-left: -20px;
        margin-left: -25px;
        margin-top: -13px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 500ms ease;
        .point {
            width: 3px;
            height: 3px;
            border-radius: 50%;
            transition: all 500ms ease;
        }
    }
}