@import '../../../../shared/scss/variables';

.argumentRating {
    font-size: 12px;
    line-height: 25px;
    color: $black;
    background: rgba($mainColor, 0.2);
    height: 45px;
    position: absolute;
    bottom: 0;
    right: 0;
    border-top-left-radius: 25px;
    opacity: 0;
    overflow-y: hidden;
    animation: fadeIn 300ms ease 500ms forwards;

    > div {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
    }

    button {
        opacity: 0.2;
        border: none;
        background: transparent;
        padding: 0;
        outline: none;
        vertical-align: sub;        
        transition: all 150ms ease;
        img {
            margin: 0 1px;
        }
    }
    button.selected {
        opacity: 1;
    }

    .stars {
        margin-left: 15px;
        display: flex;

        path {
            fill: $mainColor;
        }
    }

    .stars:hover {
        button {
            opacity: 1;
            cursor: pointer;
        }
        button:hover ~ button {
            opacity: 0.2;
        }
    }
}

.argumentRating.rated {
    opacity: 1;
    animation: fadeOut 200ms ease 3000ms forwards;
    > div {
        transform: translateY(-45px);
    }
    > div:first-child {
        transition: all 300ms ease 150ms;
    }
    > div:last-child {
        transition: all 300ms ease 250ms;
    }
}

@media screen and (max-width: 399px) {
    .argumentRating {
        height: 65px;
        width: 100%;
        border-radius: 0;
    }
    .argumentRating > div {
        flex-direction: column;
    }
    .argumentRating.rated > div {
        transform: translateY(-55px);
    }
}