@import '../../../../shared/scss/variables';

.argumentActions {
    display: flex;
    justify-content: space-between;
    color: white;
    font-size: 15px;
    line-height: 25px;

    &-info {
        max-width: 420px;
    }
    &-wrapper {
        display: flex;
    }
}

.argumentActionBtn {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background: $bgRectAccent;
    display: flex;
    align-items: center;
    padding: 0 12px;
    margin-left: 10px;
    color: white;
    overflow: hidden;
    animation: zoomIn 500ms ease 100ms forwards;
    transition: all 300ms ease-in-out;

    img {
        width: 26px;
        height: 26px;
    }

    .argumentActionBtn-text {
        margin-left: 10px;
        font-weight: bold;
        opacity: 0;
        white-space: nowrap;
        transition: all 300ms ease-in-out;
    }

    &:hover {
        width: 220px;
        background: $mainColor;
        cursor: pointer;

        .argumentActionBtn-text {
            opacity: 1;
        }
    }
}

@media screen and (max-width: 499px) {
    .argumentActions {
        flex-direction: column;
    }
    .argumentActions-wrapper {
        margin-top: 20px;
        justify-content: center;
    }
    .argumentActions-info {
        max-width: unset;
        text-align: center;
    }
}