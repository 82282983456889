.argumentsHeader {
    // background-image: linear-gradient(#ffffff, #f4f3f3);
    background-color: #FE525C;
    padding-bottom: 1px;

    h1 {
        font-size: 25px;
        font-weight: 900;
        text-align: center;
        margin: 0px 0 25px;
        color: white;
    }

    @media screen and (max-width: 399px) {
         h1 {
            font-size: 9vw;
             word-break: break-word;
        }
    }
}
