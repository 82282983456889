@import 'src/shared/scss/variables';

.impressum {
    h1 {
        font-size: 40px;
        font-weight: 900;
        text-align: center;
        margin: 20px 0 25px;
    }

    h2, h3 {
        margin-bottom: 36px;
    }
    p {
        margin-bottom: 30px;
    }
    a {
        text-decoration: none;
        font-weight: 900;
        color: $mainColor;
        transition: all 300ms;
        &:hover {
            opacity: 0.7;
        }
    }
    .divider {
        border-bottom: 1px solid;
        opacity: 0.3;
    }
}