@import '../../shared/scss/variables';

.categories {
    margin-top: 20px;
    text-align: center;
    color: $black;
    animation: fadeInFromBottom 300ms ease forwards;


    h1 {
        font-family: 'Avenir Black', sans-serif;
        font-size: 30px;
        line-height: 40px;
        font-weight: bold;
        margin-bottom: 15px;
        position: relative;
        color: white;
    }
    p {
        font-size: 15px;
        line-height: 25px;
        margin-bottom: 40px;
        font-family: 'Avenir Book', sans-serif;
        position: relative;
    }
    .backButton {
        position: absolute;
        left: -40px;
        top: 0;
        padding: 10px;
        transition: all 300ms ease;
        &:hover {
            transform: translateX(-10px) scale(1.1);
            cursor: pointer;
        }
    }
    .buttonWrapper {
        margin: 40px 0;
        opacity: 0;
        animation: fadeInFromBottom 300ms ease 300ms forwards;
    }

    @media screen and (max-width: 450px) {
        h1 {
            font-size: 6.5vw;
            line-height: 30px;
        }
        p {
            font-size: 13px;
        }
    }
}