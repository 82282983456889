@import '../../shared/scss/variables';

.arguments {
  padding: 30px 0;

  .tabs {
    display: flex;
    margin-bottom: 40px;

    .tab {
      flex: 1;
      font-size: 20px;
      font-weight: 900;
      line-height: 30px;
      color: rgba($black, 0.2);
      text-align: center;
      padding-bottom: 12px;
      position: relative;
      transition: all 300ms ease;

      &::after {
        content: '';
        width: 100%;
        height: 1px;
        background: $light-grey;
        position: absolute;
        bottom: 0;
        left: 0;
      }

      &.active {
        color: $black;

        &::after {
          height: 4px;
          background: $mainColor;
          border-radius: 5px;
          bottom: -1px;
          animation: fillFromLeft 300ms ease forwards;
        }
      }

      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
}

#printable {
  position: absolute;
  z-index: -100;
  top: 1px;
  width: 500px;
  margin-left: 30px;
  margin-right: auto;
  margin-bottom: auto;
  page-break-inside: avoid;
  h2 {
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 20px;
    letter-spacing: 0.05em;
  }
  h4 {
    color: grey;
    margin: 0 0 10px;
    font-size: 12px;
    line-height: 14px;
  }
  p {
    font-size: 12px;
    line-height: 15px;
    margin: 0 0 20px 0;
    page-break-inside: avoid;

  }
  .printableArgument {
    page-break-inside: avoid;
    margin-top:20px;
    height:200px;
  }
}
.page-break {
  page-break-after: always;
  page-break-inside: avoid;
  clear:both;
}

.downloadButton {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  margin-bottom:10px;
}

.weblinks {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

@media screen and (max-width: 650px) {
  .weblinks {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 399px) {
  .weblinks {
    grid-template-columns: repeat(1, 1fr);
  }
  .arguments .tabs .tab {
    font-size: 16px;
  }
}

.cr_site {
  margin: 0;
  padding: 75px 0 0 0;
  text-align: center;
  background-color: #eeeeee;
}

.cr_font {
  font-size: 14px;
  font-family: Arial;
}

.cr_body h2, .cr_header h2 {
  font-size: 22px;
  line-height: 28px;
}


.wrapper, .cr_page {
  margin: 0 auto 10px auto;
  text-align: left;
  border-radius: 4px;
}

.cr_header {
  text-align: center;
  background: transparent !important;
}

.cr_body label {
  float: none;
  clear: both;
  display: block;
  width: auto;
  margin-top: 8px;
  text-align: left;
  font-weight: bold;
  position: relative;
}

.cr_button {
  height: 60px;
  line-height: 60px;
  font-size: 15px;
  color: white;
  padding: 0 60px;
  background: #181722;
  border: 0;
  min-width: 200px;
  border-radius: 10px;
  box-shadow: 0 20px 50px 0 rgba(51, 85, 125, 0.15);
  outline: none;
  cursor: pointer;
  z-index:999;
}

.cr_button {
  background-color: #333;
  color: #ffffff;
}

.cr_button:hover, .cr_button-small:hover {
  opacity: 0.7;
  filter: alpha(opacity=70);
}

.powered {
  padding: 20px 0;
  width: 560px;
  margin: 0 auto;
}

.formbox {
  line-height: 150%;
  font-size: 12px;
  color: #333333;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 6px 6px 6px 6px;
}

.cr_ipe_item label {
  line-height: 150%;
  font-size: 14px;
}

.cr_ipe_item textarea {
  background: none repeat scroll 0 0 #eeeeee;
  border: 1px solid #aaa;
  font-size: 16px;
}

.cr_ipe_item .email-field {
  height: 70px;
  background: white;
  border: 2px solid #f3f5fa;
  border-radius: 10px;
  padding: 25px 20px 20px;
  text-align: center;
  font-size: 25px;
  line-height: 25px;
  font-weight: 600;
  margin-bottom: 30px;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  transition: all 500ms ease;

}

.cr_ipe_item select {
  background: none repeat scroll 0 0 #eeeeee;
  border: 1px solid #aaa;
  display: block;
  margin: 0;
  padding: 5px;
  width: 100%;
  font-size: 16px;
}

.cr_ipe_item input.cr_ipe_radio, input.cr_ipe_checkbox {
  -moz-binding: none;
  -moz-box-sizing: border-box;
  background-color: -moz-field !important;
  border: 2px inset threedface !important;
  color: -moz-fieldtext !important;
  cursor: default;
  height: 13px;
  padding: 0 !important;
  width: 13px;
}

.cr_ipe_item input.cr_ipe_radio {
  -moz-appearance: radio;
  border-radius: 100% 100% 100% 100% !important;
  margin: 3px 3px 0 5px;
}

.submit_container {
  text-align: center
}

.cr_ipe_item {
  padding: 1px 10px;
  margin: 1px 10px 0px 15px;
  position:relative;
}

.cr_button_container {
  width: 40%;
  z-index: 999;
  margin-top: 5px;
  float:right;
}

.cr_ipe_item.inactive {
  display: none;
}

.imprint {
  font-size: 0.8em;
}

.cr_captcha {
  padding-left: 130px;
}

.cr_error {
  font-size: 1.1em;
  padding: 10px;
}

.clever_form_error {
  background-color: #f99;
  color: #000;
  border: 1px solid #f22 !important;
}

.clever_form_note {
  margin: 26px 0 0 3px;
  position: absolute;
  display: inline;
  padding: 2px 4px;
  font-weight: bold;
  background-color: #f2ecb5;
  color: #000;
  font-size: 12px !important;
}

.cr_site {
  background-color: #eee;
}

.cr_header {
  color: #000000;
}

.cr_body {
  background-color: #ffffff;
  font-size: 12px;
  color: #000000;
}

.cr_hr {
  background-color: #ccc;
}

.cr_site a {
  color: #0084ff;
}

.imprint {
  color: #000;
}

.cr_site {
  background-color: #eee;
}

.cr_header {
  color: #000000;
}

.cr_body {
  background-color: #ffffff;
  font-size: 12px;
  color: #000000;
}

.cr_hr {
  background-color: #ccc;
}

.cr_site a {
  color: #0084ff;
}

.imprint {
  color: #000;
}

@media screen and (max-width: 499px) {

  .cr_ipe_item .email-field {
    font-size:20px;
    margin-bottom:5px;
  }
  .cr_ipe_item {
    width: 100%;
  }
  .cr_button_container {
    float: none;

  }

  .cr_button {
    width: 95%;
    margin-left: -8px
  }
  .cr_body {
    padding-left:0px;
  }
  .email-field {
    max-width:200px;
  }

  .argumentRating > div {
    padding: 10px 10px;
  }
}

