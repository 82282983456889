.notFound {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
        margin-bottom: 34px;
        opacity: 0;
        animation: fadeInFromBottom 300ms ease 100ms forwards;
    }
    &-text {
        margin-bottom: 30px;
        text-align: center;
        line-height: 25px;
        opacity: 0;
        animation: fadeInFromBottom 300ms ease 150ms forwards;
    }
    &-button {
        opacity: 0;
        animation: fadeInFromBottom 300ms ease 300ms forwards;
    }
}