@import '../../../../shared/scss/variables';

.argumentCard {
    background: white;
    border-radius: 25px;
    box-shadow: 0 30px 80px 0 rgba(51, 85, 125, 0.15);
    padding: 50px 50px 19px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: 190px;
    margin-bottom: 20px;
    transition: all 300ms ease-in-out;
    opacity: 0;
    animation: fadeInFromBottom 300ms ease forwards;

    &-header {
        display: flex;
        margin-bottom: 0;
    }
    &-title {
        font-size: 20px;
        line-height: 30px;
        font-weight: 900;
        margin: 0 30px 30px 0;
        flex: 1;
        &:hover {
            cursor: pointer;
        }
    }

    &-content {
        max-height: 0;
        overflow: hidden;
        transition: all 300ms ease-in-out;

        &.open {
            max-height: 2000px;
        }
    }
    &-text {
        font-size: 15px;
        line-height: 25px;
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: 1px solid $light-grey;
        transition: all 100ms ease-in;
    }

    &-resources {
        font-size: 15px;
        line-height: 25px;
        color: $blue-grey;
        transition: all 100ms ease-in;

        div {
            margin-bottom: 12px;
        }
        svg {
            margin-right: 14px;
            vertical-align: middle;
        }
        path {
            fill: $mainColor;
        }
        a {
            text-decoration: none;
            color: $mainColor;
            margin-bottom: 10px;
            transition: all 300ms ease;
        }
        a:hover {
            opacity: 0.5;
        }
    }
    &-toggle {
        position: absolute;
        background: $mainColor;
        width: 45px;
        height: 45px;
        top: 0;
        right: 0;
        border-bottom-left-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 150ms ease;
        img {
            transition: all 150ms ease;
        }
        &:hover {
            opacity: 0.8;
            cursor: pointer;
        }
    }
    .react-share__ShareButton {
        display: flex;
        align-items: center;
        position: absolute;
        left: 20px;
        bottom: 10px;
        color: #3B5998 !important;
        font-weight: bold !important;
        outline: none;
        opacity: 0;
        animation: fadeInFromBottom 300ms ease 500ms forwards;
        svg {
            margin-right: 10px;
        }
    }
}
.argumentCard.open {
    padding-bottom: 76px;
    justify-content: unset;

    .argumentCard-toggle {
        background: $light-blue-grey;
        img {
            transform: scale(1, -1);
        }
    }
    // .argumentCard-title {
    //     .title-text {
    //         top: 0;
    //         transform: translateY(0);
    //     }
    // }
    .argumentCard-text,
    .argumentCard-resources {
        opacity: 1;
        transform: translateY(0);
    }
}

.argumentBadge {
    height: 22px;
    padding: 0 10px;
    border-radius: 11px;
    background: rgba($mainColor, 0.2);
    color: $mainColor;
    font-size: 12px;
    line-height: 22px;
    font-weight: 900;
    margin: -20px 0 11px;
    width: 46px;
}

@media screen and (max-width: 499px) {
    .argumentCard {
        max-height: unset;
        &-header {
            flex-direction: column;
            align-items: center;
        }
        &-title {
            order: 2;
            margin: 0;
            text-align: center;
        }
        .react-share__ShareButton {
            position: inherit;
            left:0px;
            top:0px;
        }
    }
    .argumentCard:not(.open) {
        .argumentCard-text {
            margin: 0;
            padding: 0;
            max-height: 0;
        }
        .argumentCard-resources {
            max-height: 0;
        }
    }


}
