@import 'src/shared/scss/variables';

.intro {
    margin-top: 20px;
    text-align: center;
    color: $black;
    animation: fadeInFromBottom 300ms ease forwards;

    h3 {
        margin: 40px 0 12px;
    }
    h1, h3 {
        color: white;
    }

    .iconBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 15px;
        font-weight: 900;
        max-width: 150px;
        &-box {
            width: 80px;
            height: 80px;
            border-radius: 12px;
            // box-shadow: 0 20px 50px 0 rgba(51, 85, 125, 0.15);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
        }
    }

}
.step {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    text-align: left;

    &Number {
        width: 30px;
        height: 30px;
        border-radius: 5px;
        // background: $mainColor;
        background: transparent;
        color: white;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        flex-shrink: 0;
    }
}
.introIcons {
    display: flex;
    padding: 40px 0;
    justify-content: space-between;

    svg {
        height: 50px;
    }
    path {
        fill: $black;
    }
}
